import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid, GridColumns } from '@material-ui/data-grid';

const DEFAULT_PAGE_SIZE = 30;
const DEFAULT_PAGINATION_MODE = 'server'

const useStyles = makeStyles(theme => ({
    table: {
        marginTop: theme.spacing(1),
    },
}));

export interface DataRequest {
    page: number;
    pageSize: number;
    offset: number;
    limit: number;
}

export interface DataResponse<T> {
    count?: number;
    items?: T[];
}

export type DataLoader<T> = (request: DataRequest) => Promise<DataResponse<T>>;

export interface GridTableProps<T> {
    columns: GridColumns;
    dataLoader: DataLoader<T>;
    pageSize?: number;
    paginationMode?: 'client' | 'server';
}

export default function GridTable<T>({
    columns,
    dataLoader,
    pageSize = DEFAULT_PAGE_SIZE,
    paginationMode = DEFAULT_PAGINATION_MODE,
    ...props
}: GridTableProps<T>) {
    const classes = useStyles();

    const [page, setPage] = React.useState(0);
    const [rows, setRows] = React.useState<T[]>([]);
    const [rowCount, setRowCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);

    // load data
    React.useEffect(
        () => {
            let active = true;

            async function load() {
                setLoading(true);

                const result = await dataLoader({
                    page,
                    pageSize,
                    offset: page * pageSize,
                    limit: pageSize,
                });

                if (!active || !result) {
                    return;
                }

                const { count, items } = result;

                if (count !== undefined) {
                    setRowCount(count);
                }
                if (items !== undefined) {
                    setRows(items);
                }

                setLoading(false);
            }

            load();

            return () => {
                active = false;
            };
        },
        [page, pageSize, setRowCount, setRows, setLoading, dataLoader],
    );

    // trigger data load on page change
    function onPageChange(page: number) {
        if (paginationMode !== 'server') {
            return;
        }

        setPage(page);
    }

    return (
        <DataGrid
            autoHeight
            className={classes.table}
            columns={columns}
            hideFooter={paginationMode === undefined}
            loading={loading}
            pageSize={pageSize}
            pagination
            paginationMode={paginationMode}
            rowCount={rowCount}
            rows={rows}
            rowsPerPageOptions={[pageSize]}
            onPageChange={onPageChange}
            {...props}
        />
    );
}
