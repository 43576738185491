import { trimStart } from 'lodash';
import { parse } from 'querystring';
import React from 'react';
import { Route, useLocation, useParams } from "react-router-dom";
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { Role, RoutableComponent } from '../types';

export interface ProtectedRouteProps {
    Component: RoutableComponent,
    path: string;
    roles: Role[];
}

export function ProtectedComponent({ Component }: { Component: React.FunctionComponent }) {
    const location = useLocation();
    const params = useParams();

    const qs = parse(location.search ? location.search.substring(1) : '');

    const props = {
        ...qs,
        ...params,
    };

    return (
        <Component {...props} />
    );
 }

export default function ProtectedRoute({ Component, path, roles, ...rest }: ProtectedRouteProps) {
    // NB: it's easiest to pretend that all routable components are no-props
    let WrappedComponent: React.FunctionComponent;
    if (roles?.length) {
        // TODO: check roles and redirect away

        WrappedComponent = withAuthenticationRequired(Component as React.FunctionComponent, {
            returnTo: path,
        });
    } else {
        WrappedComponent = Component as React.FunctionComponent;
    }

    return (
        <Route exact
               path={trimStart(path, '#')}
        >
          <ProtectedComponent Component={WrappedComponent} />
        </Route>
    );
}
