import React from 'react';

import { InstanceRouteProps } from '../types';
import ErrorContext from '../context/ErrorContext';

export interface EagerLoadingOptions<T> {
    onLoad: (props: InstanceRouteProps) => Promise<T>,
}

export interface EagerLoadingProps<T> {
    data: T | null;
}

export function withEagerLoading<T>(
    Component: React.ComponentType<EagerLoadingProps<T>>,
    { onLoad }: EagerLoadingOptions<T>,
) {
    return function WithEagerLoading({ id }: InstanceRouteProps) {
        const setErrorMessage = React.useContext(ErrorContext);

        const [data, setData] = React.useState<T | null>(null);

        React.useEffect(
            () => {
                let active = true;

                async function load() {
                    try {
                        const result = await onLoad({ id });

                        if (!active || !result) {
                            return;
                        }

                        setData(result);
                    } catch (err) {
                        setErrorMessage(err.message);
                    }
                }

                load();

                return () => {
                    active = false;
                };
            },
            [id, setData, setErrorMessage],
        );

        return <Component data={data} />
    }
}
