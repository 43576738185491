import { Role } from '../types';
import { PaginatedQuery, PaginatedList, User } from '../types';

const DATA = [
    {
        id: '000000000-0000-4000-8000-000000000000',
        email: 'foo@example.com',
        role: Role.User,
    },
    {
        id: '000000000-0000-4000-8000-000000000001',
        email: 'bar@example.com',
        role: Role.User,
    },
    {
        id: '000000000-0000-4000-8000-000000000002',
        email: 'baz@example.com',
        role: Role.User,
    },
];

export interface UserQuery extends PaginatedQuery {
    prefix?: string | null;
    role?: Role | null;
}

export class Users {

    public retrieve(userId: string): Promise<User> {
        const user = DATA.filter(
            ({ id }) => id === userId,
        )[0];

        if (!user) {
            throw new Error(`No user found for: ${userId}`);
        }

        return Promise.resolve(user);
    }

    public retrieveBy(email: string): Promise<User> {
        return Promise.resolve({
            id: 'f52d5af7-62ea-438b-811e-e7bc3878183c',
            email,
            role: Role.Admin,
        });
    }

    public search({ offset, limit, prefix, role }: UserQuery): Promise<PaginatedList<User>> {
        const items = DATA.filter(
            (user) => !prefix || user.email.startsWith(prefix),
        ).filter(
            (user) => !role || user.role === role,
        );

        return Promise.resolve({
            count: items.length,
            items: items.slice(offset, offset + limit),
        });
    }
}

export const userClient = new Users();
