import React, { ChangeEvent } from 'react';
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
    },
}));

const StyledInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.secondary.light, 0.15),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        padding: theme.spacing(1),
        width: '100%',
    },
}))(InputBase);

export interface SelectFieldProps {
    choices: Record<string, string>;
    label: string,
    onChange: (value: string | null) => void;
    value: string | null;
}

export default function SelectField({ choices, label, onChange, value }: SelectFieldProps) {
    const classes = useStyles();

    function handleChange(event: ChangeEvent<{ value: unknown }>) {
        const { value } = event.target;
        onChange(value ? value as string : null);
    }

    const placeholder = label;

    return (
        <div>
          <FormControl
              className={classes.formControl}
              variant="outlined"
          >
            <Select
                displayEmpty
                id={label}
                input={<StyledInput />}
                onChange={handleChange}
                value={value || ''}
            >
              <MenuItem value={''}>
                <em>{placeholder}</em>
              </MenuItem>
              {Object.keys(choices).map((key, index) => (
                  <MenuItem
                      key={index}
                      value={key}
                  >
                    {choices[key]}
                  </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
    );
}
