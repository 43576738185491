import { ConfigType } from './types';
import { createConfig } from './variables';

const VARIABLES = {
    auth0: {
        clientId: 'The Auth0 client id',
        domain: 'The Auth0 domain',
    },
};

export type VariablesType = typeof VARIABLES;
export type Config = ConfigType<VariablesType>;

export function loadConfig(): Config {
    return createConfig(VARIABLES);
}
