import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

export interface ErrorBarProps {
    onClose: () => void;
    errorMessage: string | null;
}

function ErrorAlert({ errorMessage, onClose }: ErrorBarProps) {
    return (
        <Alert
            elevation={6}
            variant='filled'
            onClose={onClose}
            severity="warning"
        >
          {errorMessage || ''}
        </Alert>
    );

}

export default function ErrorBar({ errorMessage, onClose }: ErrorBarProps) {
    const open = errorMessage !== null;

    return (
        <Snackbar
            open={open}
            onClose={onClose}
        >
          <ErrorAlert
              errorMessage={errorMessage}
              onClose={onClose}
          />
        </Snackbar>
    );
}
