import { snakeCase } from 'lodash';

import { ConfigType, SectionType, VariablesType } from './types';

export function requireVariable(
    section: string,
    key: string,
): string {
    const name = [
        'REACT_APP',
        section.toUpperCase(),
        snakeCase(key).toUpperCase(),
    ].join('_');

    const value = process.env[name];

    if (value === undefined) {
        throw new Error(`Missing required environment variable: ${name}`);
    }

    return value;
}

export function createSection<V extends VariablesType, S extends keyof V>(
    variables: V,
    section: S,
): SectionType<V, S> {
    return Object.keys(variables[section]).reduce(
        (obj, key: keyof SectionType<V, S>) => ({
            ...obj,
            [key]: requireVariable(section as string, key as string),
        }),
        {} as SectionType<V, S>,
    );
}

export function createConfig<V extends VariablesType>(variables: V): ConfigType<V> {
    return Object.keys(variables).reduce(
        (obj, section: keyof V) => ({
            ...obj,
            [section]: createSection(variables, section),
        }),
        {} as ConfigType<V>,
    );
}
