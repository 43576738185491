import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MuiDrawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';

import { routes } from './routes';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
});

interface DrawerProps {
    onClose: () => void,
    open: boolean,
}

interface DrawerItemProps {
    displayName: string;
    onClose: () => void,
    path: string;
    Icon: React.FunctionComponent;
}

export function DrawerItem({ displayName, onClose, path, Icon }: DrawerItemProps) {
    return (
        <ListItem
            button
            key={path}
            component="a"
            href={path} onClick={onClose}>
          <ListItemIcon><Icon /></ListItemIcon>
          <ListItemText primary={displayName} />
        </ListItem>
    );
}

export default function Drawer({ onClose, open }: DrawerProps) {
    const classes = useStyles();

    const menuItems: DrawerItemProps[] = Object.keys(routes).filter(
        (path) => routes[path].Icon !== undefined && routes[path].displayName !== undefined,
    ).map(
        (path) => ({
            displayName: routes[path].displayName!,
            onClose,
            path,
            Icon: routes[path].Icon!,
        }),
    );

    return (
        <MuiDrawer
            open={open}
            onClose={onClose}>
          <div
              className={classes.list}
              role="presentation"
          >
            <div>
              <IconButton onClick={onClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>

            <Divider />

            <List>
              { menuItems.map((props, index) => {
                    return (
                        <DrawerItem key={index} {...props} />
                    );
              })}
            </List>
        </div>
      </MuiDrawer>
    );
}
