import Home from '@material-ui/icons/Home';
import People from '@material-ui/icons/People';

import { Role, RouteDefinition } from '../types';
import LandingPage from '../pages/LandingPage';
import UserPage from '../pages/UserPage';
import UserListPage from '../pages/UserListPage';

export const routes: Record<string, RouteDefinition> = {
    '#/': {
        Component: LandingPage,
        Icon: Home,
        displayName: 'Home',
        roles: [],
    },
    '#/user': {
        Component: UserListPage,
        Icon: People,
        displayName: 'Users',
        roles: [Role.Admin],
    },
    '#/user/:id': {
        Component: UserPage,
        roles: [Role.Admin],
    },
};
