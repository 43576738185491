import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    button: {
        align: 'right',
        margin: theme.spacing(1, 1.5),
    },
}));

export default function LogoutButton() {
    const classes = useStyles();

    const { logout } = useAuth0();

    return (
        <Typography
            variant="subtitle2"
            className={classes.button}>
          <Link
              color="textPrimary"
              component="button"
              onClick={() => logout({ returnTo: window.location.origin })}
          >
            logout
          </Link>
        </Typography>
    );
}
