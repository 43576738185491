import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export default function Footer() {
    const year = new Date().getFullYear();

    return (
        <span>
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link
                color="inherit"
                href="https://polycosm.io/"
            >
              Polycosm
            </Link>{' '}
            {year}
            {'.'}
          </Typography>
        </span>
  );
}
