import React from 'react';
import { BrowserRouter } from "react-router-dom";

import { loadConfig } from '../config';
import { WithAuth0, WithCurrentUser } from '../hocs';
import Layout from './Layout'

export default function App() {
    const config = loadConfig();

    return (
        <BrowserRouter>
          <WithAuth0
              config={config.auth0}
          >
            <WithCurrentUser>
              <Layout />
            </WithCurrentUser>
          </WithAuth0>
        </BrowserRouter>
    );
}
