import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import CurrentUserContext from '../context/CurrentUserContext';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'absolute',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    name: {
        align: 'right',
        margin: theme.spacing(1, 1.5),
    },
    title: {
        flexGrow: 1,
    },
}));

interface HeaderProps {
    onClick: () => void,
}

export default function Header({ onClick }: HeaderProps) {
    const classes = useStyles();

    const { isLoading } = useAuth0();

    return (
        <AppBar
            className={classes.appBar}
            position="static"
        >
          <CurrentUserContext.Consumer>
            {({ me }) => {
                return (
                    <Toolbar>
                      <IconButton
                          edge="start"
                          className={classes.menuButton}
                          color="inherit"
                          aria-label="menu"
                          onClick={onClick}
                      >
                        <MenuIcon />
                      </IconButton>
                      <Typography
                          variant="h6"
                          className={classes.title}>
                        Polycosm
                      </Typography>
                      { me && (
                            <Typography
                                variant="subtitle1"
                                className={classes.name}>
                              {me.email}
                            </Typography>
                      )}
                      { me && (
                            <LogoutButton/>
                      )}
                      { !isLoading && !me && (
                            <LoginButton/>
                      )}
                    </Toolbar>
                );
            }}
          </CurrentUserContext.Consumer>
        </AppBar>
    );
}
