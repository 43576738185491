import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';

// NB: cannot use React.StrictMode with MaterialUI at the moment without
// getting warnings due to use of `findDOMNode` in `Transition` usage.

ReactDOM.render(
    <App />,
    document.getElementById('root'),
);
