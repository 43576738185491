import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { userClient } from '../clients';
import CurrentUserContext from '../context/CurrentUserContext';
import { User } from '../types';

export interface WithCurrentUserProps {
    children: React.ReactNode,
};

export function WithCurrentUser({ children }: WithCurrentUserProps) {
    const { user } = useAuth0();

    const [me, setMe] = React.useState(null as User | null);

    React.useEffect(
        () => {
            async function loadCurrentUser(email: string): Promise<void> {
                const user = await userClient.retrieveBy(email);
                if (user) {
                    setMe(user);
                }
            }

            if (user?.email !== undefined) {
                loadCurrentUser(user.email);
            }
        },
        [user],
    );

    return (
        <CurrentUserContext.Provider value={{ me }}>
          {children}
        </CurrentUserContext.Provider>
    );
}
