import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { userClient } from '../clients';
import { PaginatedQuery, Role, User } from '../types';
import SearchField from '../components/SearchField';
import SelectField from '../components/SelectField';
import GridTable from '../components/GridTable';

const useStyles = makeStyles(theme => ({
    toolbar: {
        padding: theme.spacing(1),
    },
}));

export function DeepLink({ label, href }: { href: string, label: string}) {
    return (
        <Link href={href}>
          {label}
        </Link>
    );
}

export default function UserListPage() {
    const classes = useStyles();

    const [role, setRole] = React.useState<Role | null>(null);
    const [prefix, setPrefix] = React.useState<string | null>(null);

    const columns = [
        {
            field: 'email',
            headerName: 'Email',
            renderCell: ({ row }: { row: unknown }) => DeepLink({
                href: `#/user/${(row as User).id}`,
                label: (row as User).email,
            }),
            flex: 0.1,
        },
        {
            field: 'role',
            headerName: 'Role',
            flex: 0.1,
        },
    ];

    const dataLoader = async (query: PaginatedQuery) => userClient.search({
        ...query,
        prefix,
        role,
    });

    return (
        <div>
          <Toolbar className={classes.toolbar}>
              <Typography variant="h6" id="tableTitle" component="div">
                Users
              </Typography>

              <Divider
                  orientation="vertical"
                  flexItem
                  variant="middle"
              />
              <SearchField
                  onChange={setPrefix} />

              <SelectField
                  choices={Role}
                  label="Role"
                  onChange={(value) => setRole(value as Role)}
                  value={role}
              />
          </Toolbar>
          <GridTable
              columns={columns}
              dataLoader={dataLoader}
          />
        </div>
    );
}
