import React from 'react';
import { HashRouter } from "react-router-dom";

import { RouteDefinition } from '../types';
import ProtectedRoute from './ProtectedRoute';

export interface RouterProps {
    routes: Record<string, RouteDefinition>;
}

export default function Router({ routes }: RouterProps) {
    return (
        <HashRouter>
          { Object.keys(routes).map((path) => (
              <ProtectedRoute key={path} path={path} {...routes[path]} />
          ))}
        </HashRouter>
    );
}
