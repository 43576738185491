import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

export const mainTheme = createTheme({
    palette: {
        primary: {
            main: '#00796b',
        },
        secondary: {
            main: '#f9a825',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#f5f5f5',
        },
    },
});

export const alternateTheme = createTheme({
    palette: {
        primary: {
            main: '#bf360c',
        },
        secondary: {
            main: '#4a148c',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#f5f5f5',
        },
    },
});
