import React from 'react';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { mainTheme as theme } from '../themes';
import Drawer from './Drawer';
import Footer from './Footer';
import Header from './Header';
import MainArea from './MainArea';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
}));

export default function Layout() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    // TODO: we can switch themes here based on routing
    return (
        <ThemeProvider
            theme={theme}>
          <CssBaseline />
          <div className={classes.root}>
            <Header
                onClick={() => setOpen(true)}
            />
            <Drawer
                onClose={() => setOpen(false)}
                open={open}
            />
            <MainArea />
            <Footer  />
          </div>
        </ThemeProvider>
    );
}
