import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { userClient } from '../clients';
import { EagerLoadingProps, withEagerLoading } from '../hocs';
import { InstanceRouteProps, User } from '../types';

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(1),
    },
    text: {
        margin: theme.spacing(1),
    },
}));

export function UserPage({ data: user }: EagerLoadingProps<User>) {
    const classes = useStyles();

    if (user === null) {
        return null;
    }

    return (
        <Paper className={classes.paper}>
          <Typography className={classes.text}>
            {user.email}
          </Typography>
        </Paper>
    );
}

export default withEagerLoading<User>(UserPage, {
    onLoad: ({ id: userId}: InstanceRouteProps) => userClient.retrieve(userId),
});
