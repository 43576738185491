import React from 'react';
import { useHistory } from "react-router-dom";
import { Auth0Provider } from '@auth0/auth0-react';

import { Config } from '../config';

export interface RedirectCallback {
    returnTo?: string;
}

export interface WithAuth0Props {
    children: React.ReactNode;
    config: Config['auth0'];
}

export function WithAuth0({ children, config }: WithAuth0Props) {
    const history = useHistory();

    const onRedirectCallback = (appState?: RedirectCallback) => {
        const pathname = appState?.returnTo ?? window.location.pathname;
        history.push(pathname);
    };

    return (
        <Auth0Provider
            domain={config.domain}
            clientId={config.clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
        >
          { children }
        </Auth0Provider>
    );
}
