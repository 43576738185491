import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(1),
    },
    text: {
        margin: theme.spacing(1),
        height: 300,
    },
}));

export default function LandingPage() {
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
          <Typography className={classes.text}>
            Hello, World.
          </Typography>
        </Paper>
    );
}
