import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import ErrorContext from '../context/ErrorContext';
import Router from '../router/Router';
import ErrorBar from './ErrorBar';
import { routes } from './routes';

const useStyles = makeStyles(theme => ({
    appBarSpacer: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(4),
    },
    content: {
        flexGrow: 1,
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(4),
    },
}));

export default function MainArea() {
    const classes = useStyles();

    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

    const onClose = () => {
        setErrorMessage(null);
    }

    return (
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <ErrorContext.Provider value={setErrorMessage}>
            <Container maxWidth="lg" className={classes.container}>
              <ErrorBar errorMessage={errorMessage} onClose={onClose} />
            </Container>
            <Container maxWidth="lg" className={classes.container}>
              <Router routes={routes} />
            </Container>
          </ErrorContext.Provider>
        </main>
    );
}
